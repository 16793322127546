<template>

    <button v-if="isMobile()" class="booking-quickfinder__btn" @click="showMobileOverlay">
        <span class="booking-quickfinder__btn-label">
            {{ translate('quick-finder.Suchen & Buchen') }}
        </span>
        <span class="booking-quickfinder__btn-text">
            {{ translate('quick-finder.von Wann?') }} ·
            {{ translate('quick-finder.bis Wann?') }} ·
            {{ translate('quick-finder.mit Wem?') }}
        </span>
        <span class="booking-quickfinder__btn-icon icon icon-booking-icon" aria-hidden="true"></span>
    </button>
    <form :action="this.action" class="booking-quickfinder js-form-validation" v-if="showQuickfinder()">
        <div class="booking-quickfinder__headline" v-if="isMobile()">
            {{ translate('quick-finder.Mobile-Headline') }}
        </div>
        <div class="row" :class="isMobile() ? 'flex-column' : ''">
            <div class="col-md-3" :class="isMobile() && showDateFlyout && currentDateFlyout === 2 ? 'd-flex flex-column-reverse' : ''">
                <div class="booking-quickfinder__flyout booking-quickfinder__flyout--small" ref="dateFlyout" v-show="showDateFlyout">
                    <button class="booking-quickfinder__flyout-close" type="button" @click="toggleDateFlyout(1)"  v-if="!isMobile()">
                        x
                    </button>

                    <div class="booking-quickfinder__label text-start mb-3" v-if="isMobile () && currentDateFlyout === 1">
                        {{ translate('quick-finder.Anreise') }}
                    </div>

                    <div class="booking-quickfinder__label text-start mb-3" v-if="isMobile () && currentDateFlyout === 2">
                        {{ translate('quick-finder.Abreise') }}
                    </div>

                    <flat-pickr v-model="date" @change="changeDatepickerValue" :config="flatPickrConfig"></flat-pickr>
                </div>

                <div class="booking-quickfinder__item" :class="showDateFlyout && currentDateFlyout === 1 ? 'booking-quickfinder__item--active' : ''"
                     @click="toggleDateFlyout(1)" v-show="(!showDateFlyout && isMobile()) || (isMobile() && showDateFlyout && currentDateFlyout === 2) || !isMobile()">
                    <div class="booking-quickfinder__label">
                        {{ translate('quick-finder.Anreise') }}
                    </div>
                    <div class="booking-quickfinder__text">
                        {{ dateStart ? getDateToPrint(dateStart) : translate('quick-finder.von Wann?') }}
                    </div>
                    <input class="visually-hidden" name="from" :value="dateStart ? getAltDate(dateStart) : null" required>
                    <input class="visually-hidden" name="to" :value="dateEnd ? getAltDate(dateEnd) : null" required>
                    <input type="hidden" name="u0" :value="1">
                </div>
            </div>
            <div class="col-md-3">
                <div class="booking-quickfinder__item booking-quickfinder__item--line" :class="showDateFlyout && currentDateFlyout === 2 ? 'booking-quickfinder__item--active' : ''"
                     @click="toggleDateFlyout(2)" v-show="(!showDateFlyout && isMobile()) || (isMobile() && showDateFlyout && currentDateFlyout === 1) || !isMobile()">
                    <div class="booking-quickfinder__label">
                        {{ translate('quick-finder.Abreise') }}
                    </div>
                    <div class="booking-quickfinder__text">
                        {{ dateEnd ? getDateToPrint(dateEnd) : translate('quick-finder.bis Wann?') }}
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="booking-quickfinder__flyout" ref="flyout" v-show="showFlyout">
                    <button class="booking-quickfinder__flyout-close" type="button" @click="toggleFlyout"  v-if="!isMobile()">
                        x
                    </button>

                    <div class="booking-quickfinder__label mb-3" v-if="isMobile()">{{ translate('quick-finder.Persons') }}:</div>

                    <div class="row booking-quickfinder__flyout-item">
                        <div class="col">
                            <div class="booking-quickfinder__flyout-label">{{ translate('quick-finder.Adults') }}:</div>
                            <div class="booking-quickfinder__flyout-text">{{ translate('quick-finder.Adults-description') }}</div>
                        </div>
                        <div class="col-auto">
                            <NumberSpinner v-model="adultCounter" :max-value="adultSetup.maxValue" input-name="a0" :input-id="adultSetup.inputId" :default-value="adultCounter"></NumberSpinner>
                        </div>

                    </div>
                    <div class="row booking-quickfinder__flyout-item">
                        <div class="col">
                            <div class="booking-quickfinder__flyout-label">{{ translate('quick-finder.Children') }}:</div>
                            <div class="booking-quickfinder__flyout-text">{{ translate('quick-finder.Children-description') }}</div>
                        </div>
                        <div class="col-auto">
                            <NumberSpinner  v-model="childCounter" :max-value="childSetup.maxValue" ref="childrenNumberSpinnerRef"  input-name="c0" :input-id="childSetup.inputId"  :default-value="childCounter"></NumberSpinner>
                        </div>
                    </div>
                    <div class="booking-quickfinder__flyout-item">

                        <ul class="list-unstyled">
                            <li v-for="index in childSetup.maxValue">
                                <div class="row booking-quickfinder__child-item" :class="index > +childCounter ? 'booking-quickfinder__child-item--disabled' : ''">
                                    <div class="col-6">
                                        {{ translate('quick-finder.age-of-child') }} {{ index }}
                                    </div>
                                    <div class="col-6">
                                        <select v-model="childSetup[index]" :disabled="index > +childCounter" class="form-select" :required="index <= +childCounter" :name="'ca0[]'">
                                            <option value="" disabled selected>{{ translate('quick-finder.please-choose') }}</option>
                                            <option :value="age" v-for="age in 14">{{ getChildYear(age) }}</option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div v-show="!toggleWarning" class="booking-quickfinder__warning"> {{ translate('quick-finder.enter-child-age') }}</div>
                    </div>
                </div>

                <div class="booking-quickfinder__item booking-quickfinder__item--line" :class="showFlyout ? 'booking-quickfinder__item--active' : ''"
                     @click="toggleFlyout" v-show="(!showFlyout && isMobile()) || !isMobile()">
                    <div class="booking-quickfinder__label">
                        {{ translate('quick-finder.Persons') }}
                    </div>
                    <div class="booking-quickfinder__text" v-if="showFlyoutPlaceholder">
                        {{ translate('quick-finder.mit Wem?') }}
                    </div>
                    <div class="booking-quickfinder__text" v-else>
                        {{ adultCounter }} {{ translate('quick-finder.adult-abbr') }} & {{ childCounter }} {{ translate('quick-finder.children') }}
                    </div>
                </div>
            </div>
            <div class="col-md-3 flex-end d-flex justify-content-end">
                <div class="p-md-2 ps-md-0" :class="isMobile() ? 'w-100' : ''">
                    <button class="btn btn-booking" @click="toggleChildAgeMissingWarning" :class="isMobile() ? 'w-100' : ''" type="submit" ref="submitButton"><span class="icon icon-booking-icon btn__icon" aria-hidden="true"></span>{{ translate('quick-finder.Book') }}</button>
                </div>
            </div>
        </div>
        <button class="booking-quickfinder__mobile-close btn-no-styling d-md-none" type="button" @click="hideMobileOverlay"  v-if="isMobile()">
            <span class="icon icon-x"></span>
        </button>
    </form>
</template>

<script>
import {translate} from '@elements/translations';
import NumberSpinner from './VueNumberSpinner.vue';
import flatPickr from 'vue-flatpickr-component';
import {DE} from  'flatpickr/dist/l10n/de.js';
import {useOnClickOutside, useOnClickOutsideWithException} from "./composables/click-outside";
import {ref, watch} from 'vue';
import {ISOStringToDate, UTCDateToLocalDate, dateToISOString, localDateToUTCDate} from "@elements/date-utils";


export default {
    components: {
        NumberSpinner,
        flatPickr
    },
    show: {
        type: Boolean,
        default: false,
    },
    showDate: {
        type: Boolean,
        default: false,
    },
    props: {
        datepickerConfig: {
            type: Object,
            required: false
        },
        adultSetup: {
            type: Object,
            required: true
        },
        childSetup: {
            type: Object,
            required: true
        },
        action: {
            type: String,
            required: true
        }
    },
    setup(props) {

        const flyout = ref(null);
        const showFlyout = ref(false);

        const dateFlyout = ref(null);
        const showDateFlyout = ref(false);
        const submitButton = ref(null);

        const flatpickrConfiguration = ref({

        });

        //body click handler
        useOnClickOutsideWithException(flyout, () => {
            console.log('click', flyout);
            showFlyout.value = false;
            if (!showDateFlyout.value) {
                document.body.classList.remove('flyout-open');
            }
        });

        useOnClickOutside(dateFlyout, () => {
            console.log('click dateFlyout', dateFlyout);
            showDateFlyout.value = false;
            if (!showFlyout.value) {
                document.body.classList.remove('flyout-open');
            }
        });

        watch(
            () => props.show,
            show => {
                showFlyout.value = show;
            },
        );

        watch(
            () => props.showDate,
            showDate => {
                showDateFlyout.value = showDate;
            },
        );

        return {
            flyout,
            showFlyout,
            dateFlyout,
            showDateFlyout
        };
    },
    data() {
        return {
            currentDateFlyout: 1,
            mobileOverlayShown: false,
            adultCounter: this.adultSetup.defaultValue,
            childCounter: this.childSetup.defaultValue,
            showFlyoutPlaceholder: true,
            toggleWarning: true,
            dateStart: null,
            dateEnd: null,
            dateStartActive: false,
            dateEndActive: false,
            date: null,
            flatPickrConfig: {
                dateFormat: "Y-m-d",
                inline: true,
                mode: 'range',
                minDate: "today",
                formatDate: dateObj => dateObj.toLocaleDateString(window['_config'].lang),
                allowInput: true,
                locale: window['_config'].lang === 'de' ? 'de' : {
                    firstDayOfWeek: 1,
                },
            }
        }
    },
    methods: {
        isMobile() {
            return matchMedia('(max-width: 767px)').matches;
        },
        showQuickfinder() {
            return this.isMobile() && this.mobileOverlayShown || !this.isMobile()
        },
        getChildYear(age) {
            return age > 1 ? age + " " + translate('quick-finder.years') : age + " " + translate('quick-finder.year');
        },
        showMobileOverlay() {
            this.mobileOverlayShown = true;
            this.showDateFlyout = true;
        },
        hideMobileOverlay() {
            this.mobileOverlayShown = false;
            this.showDateFlyout = false;
        },
        toggleBodyClass(bool) {
            if(bool) {
                document.body.classList.add('flyout-open');
            } else {
                document.body.classList.remove('flyout-open');
            }
        },
        toggleFlyout() {
            this.showFlyout = !this.showFlyout;
            this.toggleBodyClass(this.showFlyout);
            this.showFlyoutPlaceholder = false;

            if(this.showDateFlyout) {
                this.showDateFlyout = !this.showDateFlyout;
            }
        },
        toggleDateFlyout(current) {
            this.showDateFlyout = !this.showDateFlyout;
            this.toggleBodyClass(this.showDateFlyout);

            if(this.showFlyout) {
                this.showFlyout = !this.showFlyout;
            }

            this.currentDateFlyout = current;
        },
       toggleChildAgeMissingWarning(){
            if(this.childCounter === 0){
                return;
            }
           this.toggleWarning = (Object.keys(this.childSetup).length - this.childSetup.length) === this.childCounter;
        },
        changeDatepickerValue(evt) {
            this.currentDateFlyout = 1;
            this.dateStart = evt.target._flatpickr.selectedDates[0];
            this.currentDateFlyout = 2;
            if(evt.target._flatpickr.selectedDates.length > 1) {
                this.dateEnd = evt.target._flatpickr.selectedDates[1];
                if(this.isMobile()) {
                    this.toggleDateFlyout();
                }

            }
        },
        getAltDate(date) {
           return dateToISOString(localDateToUTCDate(new Date(date)));
        },
        getDateToPrint(date) {
            return new Date(date).toLocaleDateString(window['_config'].lang);
        },
        translate
    }
}
</script>

